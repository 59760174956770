<template lang="pug">
v-template
  .row.d-none.d-md-block.my-3
    .col.d-flex.justify-content-between.align-items-center
      p.m-0.text-capitalize {{ title }}
      .w-75
        slot
  .row.d-sm-block.d-md-none
    .col
      b-form-group(:label="title")
        slot
</template>

<script>
import { Fragment as VTemplate } from 'vue-fragment';

export default {
  components: {
    VTemplate,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
