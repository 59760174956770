<template lang="pug">
.container
  .row.mb-4
    .col-4.d-flex.justify-content-center
      p.h6 Class Time : 2022-01-08 17:30:00
    .col-4.d-flex.justify-content-center
      p.h6 Material Title : Convenience Store (Basic)*
    .col-4.d-flex.justify-content-center
      p.h6 Material Level : 2
  b-card(
    header="Vip's Analysis"
    header-tag="header"
    footer-tag="footer"
  )
    template(#header)
      .d-flex.justify-content-between.align-items-center
        p.m-0.h6 Vip's Analysis
        b-button(size="sm" variant="primary") Back

    .row.my-3
      .col-4.d-flex.justify-content-center
        p Student's Status : Show
      .col-4.d-flex.justify-content-center
        p Student's Name : Yuki Hsu
      .col-4.d-flex.justify-content-center
        p Student's Level : 2

    hr.mb-3

    template(v-for="(field, index) in fields")
      responsive-input(:title="field")
        b-form-select(v-model="evaluation[field].value" :options="options")
      .row
        .col
          b-form-textarea(
            v-model="cannedResponses[type][field][evaluation[field].value]"
            :placeholder="placeholder"
            :rows="6"
            disabled
          ).mt-1.mb-3

    .row
      .col.d-flex.justify-content-center.align-items-center
        b-button(variant="primary") Submit
</template>

<script>
import ResponsiveInput from '@/components/responsiveColumn/index.vue';

import cannedResponses from './consultantCannedResponses.json';

export default {
  components: {
    ResponsiveInput,
  },
  data: () => ({
    // type: 'business',
    type: 'themeAndTravel',

    cannedResponses,
    options: Array(12).fill().map((data, index) => `Level ${index + 1}`),
    placeholder: 'Suggestions for improvement.',
    fields: [
      'vocabulary',
      'listening',
      'grammar',
      'speaking',
      'reading',
      'pronunciation',
    ],
    evaluation: {
      vocabulary: {
        value: null,
        content: null,
      },
      listening: {
        value: null,
        content: null,
      },
      grammar: {
        value: null,
        content: null,
      },
      speaking: {
        value: null,
        content: null,
      },
      reading: {
        value: null,
        content: null,
      },
      pronunciation: {
        value: null,
        content: null,
      },
    },
  }),
  methods: {
  },
};
</script>
